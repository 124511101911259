function urlSubcuenta() {
    // Obtener la URL actual
    const currentURL = window.location.href;
    let newURL;
  
    // Verificar si la URL contiene el string "382iyolv"
    if (currentURL.includes("382iyolv")) {
      newURL = 'https://grupo-kuo--s-a-b--de-c-v--cf-g-p-m-qas-382iyolv-qas-sel31dbb9e1.cfapps.us10.hana.ondemand.com'; // Reemplaza con la URL que deseas para este caso
    }
    // Verificar si la URL contiene el string "hwa366bf"
    else if (currentURL.includes("hwa366bf")) {
      newURL = 'https://grupo-kuo--s-a-b--de-c-v--cf-g-p-m-prd-hwa366bf-prd-sel6e69a6aa.cfapps.us10.hana.ondemand.com'; // Reemplaza con la URL que deseas para este caso
    }
    // Si no contiene ninguno de los strings anteriores, mantén la URL actual
    else {
      newURL = currentURL;
    }
  
    return newURL;
  }
  
export default urlSubcuenta;
  